const requestPermission = async() => {
  if (window.Notification.permission !== 'granted') {
    await window.Notification.requestPermission();
    console.log('[Notification] Permission is', window.Notification.permission);
  }
};

export const useNotificationApi = () => {
  return {
    requestPermission,
    send: (title, options) => {
      requestPermission().then(() => {
        const notification = new Notification(title, {
          icon: '/icon.png',
          vibrate: [200, 100, 200, 100, 200, 100, 200],
          body: options?.body,
          ...options,
        });

        notification.onclick = (event) => {
          console.log('[Notification] clicked', event);
          window.focus();
        };

        notification.onshow = (event) => {
          console.log('[Notification] show', event);
        };

        notification.onclose = (event) => {
          console.log('[Notification] close', event);
        };

        notification.onerror = (event) => {
          console.log('[Notification] error', event);
        };
      });
    },
  };
};
